import React from 'react';

import Container from 'react-bootstrap/Container'

class DataProtection extends React.Component
{
  render ()
  {
	return (
	  <Container id="dataprotection" fluid="true">
		<h1 className="legal">Datenschutzerklärung</h1>
		<h2 className="legal">Datenschutz – Allgemeines</h2>
		<p>
		  Der Verein REFORMATIONS-Campus e.V., Wiclefstraße 32, 10551 Berlin,
		  Tel.: 030-609 324 37, buero@refo-moabit.de ist
		  verantwortliche Stelle im Sinne der Datenschutzvorschriften.
		</p>
		<p>
		  Der Schutz Ihrer Daten ist uns wichtig. Deshalb verpflichten wir uns
		  zu einem transparenten und verantwortungsvollen Umgang mit Ihren Daten,
		  indem wir Ihnen nachfolgend mitteilen, was mit Ihren personenbezogenen
		  Daten passiert, wenn sie unsere Website besuchen oder andere Handlungen
		  auf unserer Website durchführen. Personenbezogene Daten werden diese
		  genannt, mit denen Sie identifiziert werden können.
		</p>
		<p>
		  Grundlage zur Verarbeitung personenbezogener Daten sind die
		  Rechtsnormen der geltenden Gesetze.
		</p>
		<h2 className="legal">I. Datenerfassung und -verarbeitung auf unserer Website</h2>
		<p>
		  Wir verarbeiten personenbezogene Daten unserer Nutzer, soweit dies
		  zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte
		  und Leistungen erforderlich ist. Die Verarbeitung personenbezogener
		  Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers.
		</p>
		<p>
		  Die Bereitstellung der personenbezogenen Daten ist nicht gesetzlich oder
		  vertraglich vorgeschrieben oder für einen Vertragsabschluss erforderlich.
		  Wenn personenbezogene Daten nicht zur Verfügung gestellt werden,
		  können bestimmte Dienstleistungen, wie ein Empfang der Newsletter nicht
		  in Anspruch genommen werden.
		</p>
		<h3 className="legal">1. Art und Weise der Datenerhebung</h3>
		<p>
		  Sie teilen uns Ihre Daten z.B. mit, indem Sie in unseren Formularen Daten übermitteln,
		  anonymisierte Daten werden erfasst, wenn Sie unsere Website benutzen (siehe unten).
		</p>
		<h3 className="legal">2. Ihre Rechte bezüglich Ihrer Daten</h3>
		<p>
		  Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und
		  Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Hierzu sowie zu
		  weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an unseren
		  Datenschutzbeauftragten wenden.
		</p>
		<h4 className="legal">a. Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
		<p>
		  Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
		  formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf
		  erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
		</p>
		<h4 className="legal">b. Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
		<p>
		  Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei
		  der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde ist die Berliner
		  Beauftragte für Datenschutz und Informationsfreiheit (www.datenschutz-berlin.de).
		</p>
		<h4 className="legal">c. Weitere Rechte</h4>
		<p>
		  Es besteht weiterhin jederzeit das Recht auf Berichtigung, auf Löschung,
		  auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit sowie ein
		  Widerspruchsrecht gegen die Verarbeitung. Hierzu sowie zu weiteren Fragen
		  zum Thema personenbezogener Daten können Sie sich jederzeit unter der im
		  Impressum angegebenen Adresse an uns oder an den Datenschutzbeauftragten wenden.
		</p>
		<h4 className="legal">1) E-Mail-Sicherheit</h4>
		<p>
		  Der Nutzer ist mit elektronischer Kommunikation einverstanden, sobald er selbst
		  den elektronischen Kontakt zum Verein REFORMATIONS-Campus e.V. eröffnet.
		  Der Nutzer wird darauf hingewiesen, dass E-Mails auf dem Übertragungsweg unbefugt
		  und unbemerkt mitgelesen oder verändert werden können, wenn sie nicht verschlüsselt sind.
		</p>
		<p>
		  Der Verein REFORMATIONS-Campus e.V. verwendet eine Software zur Filterung von unerwünschten
		  E-Mails (Spam-Filter). Durch den Spam-Filter können E-Mails abgewiesen werden, wenn diese
		  durch bestimmte Merkmale als Spam identifiziert wurden.
		</p>
		<h2 className="legal">II. Bereitstellung der Website und Erstellung von Logfiles</h2>
		<p>
		  Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten
		  und Informationen vom Computersystem des aufrufenden Rechners.<br />
		  Folgende Daten werden hierbei erhoben:
		</p>
		<ul>
		  <li>Informationen über den Browser und die verwendete Version</li>
		  <li>Das Betriebssystem des Nutzers</li>
		  <li>die IP-Adresse mit genullter letzter Stelle.
		  Es kann somit keine Personenzugehörigkeit mehr nachvollzogen werden</li>
		  <li>Datum und Uhrzeit des Zugriffs</li>
		  <li>Websites, von denen das System des Nutzers auf unsere Internetseite gelangt</li>
		</ul>
		<h2 className="legal">III. Cookies</h2>
		<p>Wir verwenden keine Cookies auf unserer Website.</p>
		<h2 className="legal">Ansprechpartner</h2>
		<p>
		  Der Verein REFORMATIONS-Campus e.V. beschäftigt hauptamtlich einen
		  Datenschutzbeauftragten, der sich um den transparenten Umgang mit Ihren Daten kümmert:
		</p>
		<p>
		  Sebastian Leenen<br />
		  Wiclefstraße 32<br />
		  10551 Berlin<br />
		  Deutschland<br />
		  E-Mail: datenschutz@refo-moabit.de<br />
		  Website: www.refo-moabit.de
		</p>
	  </Container>
	);
  }
}

export default DataProtection;
