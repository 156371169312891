import React from 'react';

import Figure from 'react-bootstrap/Figure'
import Jumbotron from 'react-bootstrap/Jumbotron'
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card'

import neapolis_title from './img/neapolis_T1_v2.jpg'
import impression_1 from './img/neapolis_1760.jpg'
import impression_2 from './img/neapolis_1302.jpg'
import impression_3 from './img/neapolis_1626.jpg'

class Info extends React.Component
{
  render ()
  {
	return (
	  <Jumbotron fluid className="jumbo_gray" id="info">
		<h2 className="main">Kurzkonzept der Néapólis</h2>
		<h1 className="main">NÉAPÓLIS IST EIN TRANSUTOPISCHES FESTIVAL</h1>
		<h3 className="main">
		  Als zweitägige innerstädtische Erlebnissveranstaltung eröffnet
		  Néapólis Spielräume um zukünftig Mögliches zu
		  erfahren. Perspektiven auf Themen des lokalen bis globalen
		  Zusammenlebens werden in bunter Gemeinschaft ausgetauscht,
		  zusammen gedanklich erprobt und gefühlvoll erweitert.
		</h3>
		<p>
		  Néapólis, die neue Stadt ist die Verheißung von persönlicher Freiheit
		  und gelingender Kooperation: ein Ort, an dem Menschen aus der
		  Anonymität heraustreten und ihre Kompetenzen in die Gestaltung der
		  Gesellschaft einbringen können. Mit dem Festival Néapólis wird die
		  neue Gesellschaft zwei Tage mitten in Berlin lebendig.
		  Zukünftiges wird erträumt, geplant und gefeiert.
		</p>
		<p>
		  Veranstaltungsort ist der REFORMATIONS-Campus in Berlin-Moabit, ein
		  Ort, an dem bereits seit einigen Jahren Gemeinschaft in Vielfalt
		  gelebt wird. Saal und Wiclefplatz werden zum Markt- und Umschlagplatz
		  von leckerem Essen und blühenden Ideen. Bei Konzerten, Installationskunst
		  und Performance wird in der Kirche
		  beispielhaft erlebbar, wie ein Ort verwandelt werden kann. Workshops
		  und Seminare bieten die Möglichkeit in einzelne Themenfelder intensiv
		  einzusteigen.
		</p>
		<p>
		  Néapólis ist ein Ort für alle Moabiter*innen und Berliner*innen,
		  die wirksam werden wollen im Gestalten von Stadt und Gesellschaft.
		  Konzipiert als jährliches transutopisches Festival, wird Néapólis vom
		  31. Mai 2019 bis 1. Juni 2019 mit dem Themen­schwerpunkt gerechter
		  Frieden erstmals umgesetzt.
		</p>
	  </Jumbotron>
	);
  }
}

class Program extends React.Component
{
  render ()
  {
	return (
	  <Jumbotron fluid className="jumbo_white" id="program">
		<h2 className="main">Néapólis Festival-Programm</h2>
		<h1 className="main">NÉAPÓLIS: 31. MAI + 1. JUNI 2019</h1>
		<h3 className="main">
		  Willkommen in Néapólis!
		  Der REFO-Campus in Berlin-Moabit verwandelt sich in eine neue Stadt.
		  In Néapólis probieren wir in bunter Atmosphäre aus, ob schöne Utopien
		  wie, wann, wo und in welchen Kombinationen zusammenspielen, damit unser
		  Zusammenleben in aller Vielfalt weiter wachsen kann.
		  Deshalb erlebt ihr hier ein mitreißendes „Podium Party Peace“, traumhafte akustische
		  und elektronische Konzerte in magischer Lichtkunst, durchdachte Seminare, Workshops
		  für Körper und Geist... und ein chilliges Miteinander auf dem Markt.
		</h3>
		<CardDeck>
		  <Card bg="light" border="light">
			<Card.Body>
			  <Card.Title>
				ZUKUNFTMUSIK IM KIRCHENSCHIFF
			  </Card.Title>
			  <Card.Subtitle className="mb-2 text-muted">
				Freitag 31. Mai 2019. 19:00-23:00. <br/> Podium Party Peace
			  </Card.Subtitle>
			  <Card.Text>
				<b>Think Tank</b> „Utopischer Alltag“ mit #unteilbar, Fridays For Future,
				Neues Forum und Sicherheit neu denken :: <b>Happening and Party</b> mit
				Julia Bihl, Rike Flämig und The Tinker Tapes
				:: <b>Sound and Peace</b> mit Renaissance-Gesang von Prosecchor und Texten von Rumi,
				Acapella-Klängen und DJ boncel.
			  </Card.Text>
			  <Card.Subtitle className="mb-2 text-muted">
				Samstag 01. Juni 2019. 15:00–22:00. Konzertbühne
			  </Card.Subtitle>
			  <Card.Text as="div">
				<p className="lineup">15:00 <br/> <b>Kelp</b> (Electronica and Bass)</p>
				<p className="lineup">15:40 <br/> <b>Zhenya Topov</b> (Handpan)</p>
				<p className="lineup">16:25 <br/> <b>Bohai</b> (Guitar, Contrabass, Bass Clarinet, Vocals)</p>
				<p className="lineup">17:25 <br/> <b>Suburban Dinosaur</b> (Piano and E-Guitar)</p>
				<p className="lineup">18:15 <br/> <b>The Tinker Tapes</b> (Electronic Improvisation)</p>
				<p className="lineup">19:15 <br/> <b>Ohio Partita</b> (Strings and Keys)</p>
				<p className="lineup">20:15 <br/> <b>Reise</b> (Vibraphone and Cello)</p>
				<p className="lineup">21:15 <br/> <b>Andreas Levisianos</b> (Piano and Electronics)</p>
			  </Card.Text>
			</Card.Body>
		  </Card>
		  <Card bg="light" border="light">
			<Card.Body>
			  <Card.Title>MARKT UND BIERGARTEN</Card.Title>
			  <Card.Subtitle className="mb-2 text-muted">
				Freitag, 31. Mai 2019. 17.00–21.00 und<br/>
				Samstag, 1. Juni 2019. 15.00–21.00.
			  </Card.Subtitle>
			  <Card.Text as="div">
				<b>Markt auf dem Wiclefplatz</b>
				<p className="lineup">
				  Frei nach dem Motto: „Es ist nie zu spät für eine glückliche Kindheit“,
				  findet ihr auf dem Néapólis-Festplatz himmlische Köstlichkeiten und knallige Ideen
				  für jede Menge Freude. Berliner Initiativen kommen ins Gespräch; Interessierte und
				  Kreative tauschen sich aus – auf dem Chillout-Sofa im Seifenblasenregen oder bei Pizza,
				  Falafel und Gegrilltem.
				  Samstag gibt es auch Kaffee und hausgemachten Kuchen vom Feinsten.
				  Leckere Limos, Biere und Cocktails gibt es sowieso.
				</p>
				<p className="lineup">
				  Sehr interessant: Die Filmschule Bamenda wird auf dem Markt in Néapólis einen Stand haben
				  und neben ihren filmischen Werken auch leckere Gerichte aus Kamerun präsentieren.
				</p>
			  </Card.Text>
			</Card.Body>
		  </Card>
		  <Card bg="light" border="light">
			<Card.Body>
			  <Card.Title>WORKSHOPS UND SEMINARE</Card.Title>
			  <Card.Subtitle className="mb-2 text-muted">
				Freitag, 31. Mai 2019. 17.00–18:30. Kita
			  </Card.Subtitle>
			  <Card.Text as="div">
				<p className="lineup">
				  Einführung in die Gewaltfreie Kommunikation (mit dem GfK-Coach und -Trainer Christian Bliss)
				</p>
				<p className="lineup">
				  Pranayama, innerer Friede durch Atemerfahrung (in Englisch mit dem jordanischen Yogalehrer Yazan Majed Qandah)
				</p>
			  </Card.Text>
			  <Card.Subtitle className="mb-2 text-muted">
				Samstag, 1. Juni 2019. 15.00–16.30. Kita
			  </Card.Subtitle>
			  <Card.Text as="div">
				<p className="lineup">
				  Die Friedliche Revolution 89/90 – wie Visionen Wirklichkeit verändern (mit Heiko Lietz von Neues Forum)
				</p>
				<p className="lineup">
				  #unteilbar – Bewegung werden!
				</p>
				<p className="lineup">
				  Durch die Praxis der Achtsamkeit zu einem neuen Miteinander (mit der Therapeutin für Mind-Body-Medizin Britta Jörg)
				</p>
			  </Card.Text>
			  <Card.Subtitle className="mb-2 text-muted">
				Samstag, 1. Juni 2019. 17.00–18.30. Kita
			  </Card.Subtitle>
			  <Card.Text as="div">
				<p className="lineup">
				  D 2040 – Ein Land ohne Armee und Waffenindustrie (mit Ralf Becker vom Bündnis Sicherheit neu denken)
				</p>
				<p className="lineup">
				  #actnow mit Fridays for Future
				</p>
				<p className="lineup">
				  Pranayama, innerer Friede durch Atemerfahrung (in Englisch mit dem jordanischen Yogalehrer Yazan Majed Qandah)
				</p>
			  </Card.Text>
			</Card.Body>
		  </Card>
		</CardDeck>
		<p className="donation">
		  Der Eintritt zum gesamten Festival ist frei.
		  Über Spenden freuen wir uns.
		</p>
	  </Jumbotron>
	);
  }
}

class Impressions extends React.Component
{
  render ()
  {
	return (
	  <Jumbotron fluid className="jumbo_gray" id="impressions">
		<h2 className="main">Impressionen</h2>
		<h1 className="main">EVENTS IN DER REFO MOABIT</h1>
		<CardDeck>
		  <Card>
			<Card.Img variant="top" src={impression_1} width="400px"/>
		  </Card>
		  <Card>
			<Card.Img variant="top" src={impression_2} width="400px"/>
		  </Card>
		  <Card>
			<Card.Img variant="top" src={impression_3} width="400px"/>
		  </Card>
		</CardDeck>
	  </Jumbotron>
	);
  }
}

class Contact extends React.Component
{
  render ()
  {
	return (
	  <Jumbotron fluid className="jumbo_white" id="contact">
		<h1 className="main">KONTAKT</h1>
		<h3 className="main">
		  Interesse das Néapólis-Festival zu unterstützen? Oder gibt es Fragen, Wünsche und Hinweise?<br/>
		  Wir freuen uns über Mitteilungen und vor allem über Besuche zum Event vom 31. Mai bis 1. Juni 2019.
		</h3>
		<p className="center">
		  Néapólis-Festival<br/>
		  REFORMATIONS-Campus<br/>
		  Wiclefstraße 32<br/>
		  10551 Berlin<br/>
		</p>
		<p className="center">
		  Tel. +49 30 60932310<br/>
		  kontakt@neapolis-festival.de
		</p>
	  </Jumbotron>
	);
  }
}

class MainPage extends React.Component
{
  render ()
  {
	return (
	  <>
		<Figure id="home">
		  <Figure.Image
			src={neapolis_title}
			alt="neopólis"
		  />
		</Figure>
		<Info/>
		<Program/>
		<Impressions/>
		<Contact/>
	  </>
	);
  }
}

export default MainPage;
