import React from 'react';
import ReactDOM from 'react-dom';

import DataProtection from './data_protection.js';
import Imprint from './imprint.js';
import HeaderNavBar from './header.js';
import FooterNavBar from './footer.js';
import MainPage from './main_page.js';

import WebFont from 'webfontloader';

import './bootstrap.min.css';
import './index.css';

import mdfile from './markdown/infos.md';

WebFont.load({
  google: {
	families: ['Quicksand:300,400,700', 'sans-serif']
  }
});

const Remarkable = require('remarkable');
var md = new Remarkable();

class InfosForActs extends React.Component
{
  constructor(props) {
    super(props)
	this.state = { markdown: null }
  }

  componentDidMount () {
	fetch(mdfile)
	  .then(response => {return response.text()})
	  .then(text => {
		this.setState({markdown: md.render(text)})
	  })
  }

  render ()
  {
	const { markdown } = this.state;
	return (
	  <div className="infoacts" dangerouslySetInnerHTML={{__html: markdown}}/>
	  // md.render(infosmd)
	);
  }
}

class NeapolisApp extends React.Component
{
  header_menu_items = {
	'info': 'INFO',
	'program': 'PROGRAMM',
	'impressions': 'IMPRESSIONEN',
	'contact': 'KONTAKT',
  };

  header_menu_info_items = {
	'info': 'INFOS FOR ACTS',
  };

  footer_menu_items = {
	'impressum': 'Impressum',
	'dataprotection': 'Datenschutzerklärung',
  };

  constructor(props, context) {
	super(props, context);
	this.state = {
	  show_main: true,
	  show_impressum: false,
	  show_dataprotect: false,
	};
  }

  toggle_state (clicked_link)
  {
	switch(clicked_link)
	{
	  case "impressum":
		this.setState({
		  show_main: false,
		  show_impressum: true,
		  show_dataprotect: false,
		});
		break;
	  case "dataprotection":
		this.setState({
		  show_main: false,
		  show_impressum: false,
		  show_dataprotect: true,
		});
		break;
	  default:
		this.setState({
		  show_main: true,
		  show_impressum: false,
		  show_dataprotect: false,
		});
		break;
	}

	this.element = document.getElementById(clicked_link);

	if (!this.element) return;

	if (clicked_link === "home")
	  this.align = false;
	else
	  this.align = true;

	this.element.scrollIntoView(this.align);
  }

  render_page ()
  {
	if (this.state.show_main)
	  return (
		<MainPage/>
	  );
	if (this.state.show_impressum)
	  return (
		<Imprint/>
	  );
	if (this.state.show_dataprotect)
	  return (
		<DataProtection/>
	  );
  }

  render () {
	if (window.location.pathname.substring(1,5) === "info")
	  return (
		<>
		  <HeaderNavBar
			onClick_dg={this.toggle_state.bind(this)}
			menu={this.header_menu_info_items}
		  />
		  <InfosForActs/>
		</>
	  );
	else
	  return (
		<>
		  <HeaderNavBar
			onClick_dg={this.toggle_state.bind(this)}
			menu={this.header_menu_items}
		  />
		  {this.render_page()}
		  <FooterNavBar
			onClick_dg={this.toggle_state.bind(this)}
			menu={this.footer_menu_items}
		  />
		</>
	  );
  }
}
// ========================================

ReactDOM.render(
  <NeapolisApp />,
  document.getElementById('root')
);
