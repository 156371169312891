import React from 'react';

import Container from 'react-bootstrap/Container'

class Imprint extends React.Component
{
  render ()
  {
	return (
	  <Container fluid="true">
		<h1 className="legal">IMPRESSUM</h1>
		<p>
		  Das Néapólis-Festival ist ein sich entwickelndes Kooperationsprojekt des
		  REFORMATIONS-Campus e.V. und Kulturschaffenden sowie sozialen Initiativen.
		</p>
		<p>
		  <strong>Angaben gemäß § 5 TMG</strong><br/>
		  REFORMATIONS-Campus e.V.<br/>
		  Wiclefstraße 32<br/>
		  10551 Berlin<br/>
		  buero@refo-moabit.de
		</p>
		<p>
		  Der Verein ist gemeinnützig und ist im Vereinsregister eingetragen:<br/>
		  Registernummer: VR 33.853 B beim Amtsgericht Charlottenburg Berlin.<br/>
		  Vertretungsberechtigter Vorstand: Generalsuperintendentin Ulrike Trautwein,
		  Stephan Rauhut (Vorsitz), Hanna Faißt
		</p>
		<p>
		  <strong>Inhaltlich verantwortlich gemäß § 55 Abs. 2 RStV:</strong><br/>
		  Tobias Horrer (Anschrift wie oben)<br/>
		  t.horrer@refo-moabit.de
		</p>
		<p>
		  <strong>Webdesign:</strong><br/>
		  ImpulsKontur.de
		  (Projekt, Entwurf, Bilder: Johanna Lehmeier,
		  Programmierung: Matthias Wende)
		</p>
	  </Container>
	);
  }
}

export default Imprint;
