import React from 'react';

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

class FooterNavBar extends React.Component
{
  render_menu_item (key, menu_item)
  {
	return(
	  <Nav.Item key={key}>
		<Nav.Link 
		  className="footer"
		  onClick={() => this.props.onClick_dg(key)}
		  key={key}
		>
		  {menu_item}
		</Nav.Link>
	  </Nav.Item>
	)
  }

  render_menu_items (menu_items)
  {
	var menu_list = [];

	for (var key in menu_items)
	{
	  menu_list.push(this.render_menu_item(key, menu_items[key]));
	}

	return menu_list;
  }

  render ()
  {
	return (
	  <Navbar className="footer" bg="navbar_cyan" variant="dark" sticky="bottom">
		<div className="w-100">
		  Copyright 2019 Néapólis-Festival
		</div>
		<Nav className="justify-content-lg-end">
		  {this.render_menu_items(this.props.menu)}
		</Nav>
	  </Navbar>
	);
  }
}

export default FooterNavBar;
