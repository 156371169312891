import React from 'react';

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import neapolis_logo from './img/neapolis_L2.png'

class HeaderNavBar extends React.Component
{
  render_menu_item (key, menu_item)
  {
	return(
	  <Nav.Item key={key}>
		<Nav.Link 
		  onClick={() => this.props.onClick_dg(key)}
		  key={key}
		  href="#"
		>
		  {menu_item}
		</Nav.Link>
	  </Nav.Item>
	)
  }

  render_menu_items (menu_items)
  {
	var menu_list = [];

	for (var key in menu_items)
	{
	  menu_list.push(this.render_menu_item(key, menu_items[key]));
	}

	return menu_list;
  }

  render ()
  {
	return (
	  <Navbar
		collapseOnSelect
		bg="navbar_red"
		expand="lg"
		variant="dark"
		fixed="top"
	  >
		<Navbar.Brand
		  href={this.props.menu[0]}
		  onClick={() => this.props.onClick_dg("home")}
		>
		  <img width="140px" alt="neapolis" src={neapolis_logo}/>
		</Navbar.Brand>
		<Navbar.Toggle aria-controls="basic-navbar-nav" />
		<Navbar.Collapse className="justify-content-lg-end" id="navbar-collapse">
		  <Nav>
			{this.render_menu_items(this.props.menu)}
		  </Nav>
		</Navbar.Collapse>
	  </Navbar>
	);
  }
}

export default HeaderNavBar;
